import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpResponse,
} from "@angular/common/http";
import { tap } from "rxjs/operators";
import { Observable } from "rxjs";
import { ApiService } from "./api.service";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
import { SessionService } from "./session.service";

@Injectable({
  providedIn: "root",
})
export class InterceptorService implements HttpInterceptor {
  constructor(
    private api: ApiService,
    private toastr: ToastrService,
    public router: Router,
    private sessionService: SessionService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Clone the request to add the new header.
    if (this.sessionService.get("token")) {
      req = req.clone({
        headers: req.headers.set(
          "authorization",
          "bearer " + this.sessionService.get("token")
        ),
      });
    }
    if (req.reportProgress === true) {
    }

    return next.handle(req).pipe(
      tap(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            // this.toastr.success(event.body.message);
          }
        },
        (err: any) => {
          if (err instanceof HttpErrorResponse) {
            if (err.error.statusCode === 401) {
              this.sessionService.remove("token");
              this.sessionService.remove("userData");
              this.router.navigate(["/login"]);
              this.toastr.error(
                `<div class="alert-text"><span data-notify="message">${
                  err.error.message ||
                  "There is some issue with the server, we are fixing it asap!"
                }</span></div>`,
                "",
                {
                  timeOut: 4000,
                  closeButton: false,
                  enableHtml: true,
                  tapToDismiss: false,
                  titleClass: "alert-title",
                  positionClass: "toast-top-right",
                  toastClass: "ngx-toastr alert alert-dismissible",
                }
              );
            } else {
              this.toastr.error(
                `<div class="alert-text"><span data-notify="message">${
                  err.error.message ||
                  "There is some issue with the server, we are fixing it asap!"
                }</span></div>`,
                "",
                {
                  timeOut: 4000,
                  closeButton: false,
                  enableHtml: true,
                  tapToDismiss: false,
                  titleClass: "alert-title",
                  positionClass: "toast-top-right",
                  toastClass: "ngx-toastr alert alert-dismissible",
                }
              );
            }
          } else {
            this.toastr.error(
              `<div class="alert-text"><span data-notify="message">${
                err.error.message || "Please check your internet connection!"
              }</span></div>`,
              "",
              {
                timeOut: 4000,
                closeButton: false,
                enableHtml: true,
                tapToDismiss: false,
                titleClass: "alert-title",
                positionClass: "toast-top-right",
                toastClass: "ngx-toastr alert alert-dismissible",
              }
            );
          }
        }
      )
    );
  }
}
