import { Injectable } from "@angular/core";
import { AngularFireMessaging } from "@angular/fire/messaging";
import { BehaviorSubject } from "rxjs";
import { mergeMapTo } from "rxjs/operators";
@Injectable()
export class MessagingService {
  currentMessage = new BehaviorSubject(null);
  token = new BehaviorSubject(null);
  constructor(private angularFireMessaging: AngularFireMessaging) {
    this.angularFireMessaging.messages.subscribe((_messaging: any) => {
      _messaging.onMessage = _messaging.onMessage.bind(_messaging);
      _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
    });
  }
  requestPermission() {
    this.angularFireMessaging.requestPermission
      .pipe(mergeMapTo(this.angularFireMessaging.tokenChanges))
      .subscribe(
        (token) => {
          // console.log("Permission granted! Save to the server!", token);
          this.token.next(token);
        },
        (error) => {}
      );
  }
  // requestPermission() {
  //   this.angularFireMessaging.requestToken.subscribe(
  //     (token) => {
  //       console.log(token);
  //     },
  //     (err) => {
  //       console.error("Unable to get permission to notify.", err);
  //     }
  //   );
  // }
  // receiveMessage() {
  //   this.angularFireMessaging.messages.subscribe((payload) => {
  //     console.log("new message received. ", payload);
  //     this.currentMessage.next(payload);
  //   });
  // }
  receiveMessage() {
    this.angularFireMessaging.messages.subscribe((message) => {
      this.currentMessage.next(message);
    });
  }
}
