import { Component, OnInit, ElementRef, Renderer2 } from "@angular/core";
import {
  Router,
  Event,
  NavigationStart,
  NavigationEnd,
  NavigationError,
} from "@angular/router";
import { SessionService } from "../../services/session.service";
import { ApiService } from "../../services/api.service";
import { SocketService } from "../../services/socket.service";

import {
  Location,
  LocationStrategy,
  PathLocationStrategy,
} from "@angular/common";
import { apiConstants } from "../../shared/api.constants";
import { MessagingService } from "src/app/services/messaging.service";
import { MixpanelService } from "../../services/mixpanel.service";
@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
})
export class NavbarComponent implements OnInit {
  userData: any;
  subscription: any;
  confirmPopUp = false;
  showVerificationMessage = true;
  isLoading = false;
  subscribeVerification: any;
  constructor(
    location: Location,
    private element: ElementRef,
    private socketService: SocketService,
    private router: Router,
    private sessionService: SessionService,
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private apiService: ApiService,
    private ms: MessagingService,
    private mixpanelService: MixpanelService
  ) {
    this.socketService.connectSocket();
  }
  notificationToggle() {
    this.mixpanelService.track("Notification Checked", {});
    this.renderer.addClass(
      this.elementRef.nativeElement.querySelector("#sidebar_false"),
      "__active"
    );
  }
  watchForNavToggle() {
    if (this.elementRef.nativeElement.querySelector(".__active")) {
      return 1;
    } else {
      return 0;
    }
  }
  ngOnInit() {
    this.subscription = this.sessionService
      .getProfileDetail()
      .subscribe((data) => {
        if (data) this.userData = data;
      });

    this.ms.currentMessage.subscribe((msg) => {
      this.fetchData();
    });
    this.subscribeVerification = this.sessionService
      .getChangedVerification()
      .subscribe((message) => {
        this.fetchData();
      });

    this.sessionService.newEditDriver.subscribe((res) => {
      this.fetchData();
    });
    this.sessionService.newLowerBid.subscribe((res) => {
      this.fetchData();
    });
  }

  verified() {
    this.userData.verificationChecked = true;
    this.showVerificationMessage = false;
    let body = {};
    body["verificationChecked"] = true;
    this.apiService
      .postData(apiConstants.PROFILE.EDIT.URL, body, true)
      .subscribe((res) => {
        this.fetchData();
        // ------ ADD ANY SUCCESS MESSAGE OVER HERE
      });
  }

  fetchData() {
    let bodyParams = {
      deviceType: "WEB",
    };
    this.isLoading = true;
    this.apiService
      .postData(apiConstants.PROFILE.DETAIL.URL, bodyParams, true)
      .subscribe((res) => {
        this.userData = res.data;
        this.isLoading = false;
      });
  }

  // -------- LOGOUT METHOD
  logout() {
    this.apiService
      .postData(apiConstants.AUTH.LOGOUT.URL, {}, true)
      .subscribe((res) => {
        this.sessionService.remove("token");
        this.sessionService.remove("addDriver");
        this.sessionService.remove("driver_id");
        this.sessionService.remove("userData");
        this.mixpanelService.track("Logout", {});
        this.socketService.disconnectSocket();
        this.router.navigate(["/login"]);
      });
  }
}
