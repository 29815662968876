import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";

import { AuthLayoutComponent } from "./layouts/auth-layout/auth-layout.component";
import { AdminLayoutComponent } from "./layouts/admin-layout/admin-layout.component";

import { AuthgaurdService } from "./services/authgaurd.service";
import { LoginguardService } from "./services/loginguard.service";

const routes: Routes = [
  {
    path: "",
    redirectTo: "login",
    pathMatch: "full",
  },
  {
    path: "print",
    loadChildren:
      "./common/printable-forms/printable-forms.module#PrintableFormsModule",
  },
  {
    path: "",
    canActivate: [AuthgaurdService],
    component: AdminLayoutComponent,
    children: [
      {
        path: "drivers",
        loadChildren: "./pages/drivers/drivers.module#DriversModule",
      },
      {
        path: "driver-details",
        loadChildren:
          "./pages/driver-details/driver-details.module#DriverDetailsModule",
      },
      {
        path: "orders",
        loadChildren: "./pages/orders/orders.module#OrdersModule",
      },
      {
        path: "profile",
        loadChildren: "./pages/profile/profile.module#ProfileModule",
      },
      {
        path: "add-driver",
        loadChildren: "./pages/add-driver/add-driver.module#AddDriverModule",
      },
      {
        path: "all-bids",
        loadChildren: "./pages/bidding/all-bids/all-bids.module#AllBidsModule",
      },
      {
        path: "my-bids",
        loadChildren: "./pages/bidding/my-bids/my-bids.module#MyBidsModule",
      },
    ],
  },
  {
    path: "",
    canActivate: [LoginguardService],
    component: AuthLayoutComponent,
    children: [
      {
        path: "",
        loadChildren:
          "./layouts/auth-layout/auth-layout.module#AuthLayoutModule",
      },
    ],
  },
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes, {
      useHash: true,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
