// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
  production: true,
  API_ENDPOINT: "https://api.trukkin.com",
  firebase: {
    apiKey: "AIzaSyCcqadUPWZi81hz3l0pYO0pbSE-xyUZ_wg",
    authDomain: "transporter-app-d8f25.firebaseapp.com",
    projectId: "transporter-app-d8f25",
    storageBucket: "transporter-app-d8f25.appspot.com",
    messagingSenderId: "10014513040",
    appId: "1:10014513040:web:c438ede3d3da8abf9e8515",
    measurementId: "G-NZFDHHSCPQ",
  },
  MIX_PANEL_KEY: "6fe7a6823775428a7175fdfc5d42b739",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
