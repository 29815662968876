import { Injectable, OnInit } from "@angular/core";
// import { io } from "socket.io-client";
import * as io from "socket.io-client";
import { Socket } from "ngx-socket-io";
import { ApiService } from "./api.service";
import { environment } from "../../environments/environment";
import { SessionService } from "./session.service";

@Injectable({
  providedIn: "root",
})
export class SocketService implements OnInit {
  private url = environment.API_ENDPOINT;
  socket: any;

  constructor(
    public sessionService: SessionService,
    private api: ApiService // private socket: Socket
  ) {}

  ngOnInit() {}

  connectSocket() {
    // Set token as part of the query object
    this.socket = io(this.url, {
      query: "tenderToken=" + this.sessionService.get("token"),
      // forceNew: true,
      reconnection: false,
      timeout: 60000,
      autoConnect: true,
    });
    this.socket.on("error", () => {
      console.log("socket error");
    });
    this.socket.on("connect_error", (data) => {
      console.log("socket connect_error");
    });
    this.socket.on("connect_timeout", () => {
      console.log("socket connect_timeout");
    });
    this.socket.on("reconnect", () => {
      console.log("socket reconnect");
    });
    this.socket.on("reconnect_error", () => {
      console.log("socket reconnect_error");
    });
    this.socket.on("reconnect_failed", () => {
      console.log("socket reconnect_failed");
    });
    this.socket.on("reconnect_attempt", () => {
      console.log("socket reconnect_attempt");
    });
    this.socket.on("reconnecting", () => {
      console.log("socket reconnecting");
    });
    this.socket.on("newAssignment", (data) => {
      console.log("socket new assignment");
      this.sessionService.onNewOrderData(data);
    });
    this.socket.on("latestLocation", (data) => {
      console.log("socket on location change of driver in ongoing orders");
      this.sessionService.onNewLocationData(data);
    });
    this.socket.on("verifyTender", (data) => {
      console.log("socket verify transporter");
      this.sessionService.changeVerification(data);
    });
    this.socket.on("bidWonAssignDriver", (data) => {
      console.log("socket driver bid won");
      this.sessionService.onNewBidAssignedData(data);
    });
    this.socket.on("driverAssignedToTransporter", (data) => {
      console.log("socket driver edit");
      this.sessionService.onEditDriverData(data);
    });
    this.socket.on("applyJobLowerBid", (data) => {
      console.log("socket apply for lower bid");
      this.sessionService.onEditLower(data);
    });
    this.socket.on("applyJob", (data) => {
      console.log("socket apply job");
      this.sessionService.onEditLower(data);
    });
  }

  disconnectSocket() {
    this.socket.disconnect();
    this.socket.on("disconnect", function () {});
  }

  ngOnDestroy() {}
}
