import { Component, OnInit, HostListener } from "@angular/core";
import { ApiService } from "../../services/api.service";
import { SessionService } from "../../services/session.service";
import { apiConstants } from "../../shared/api.constants";
import { MessagingService } from "src/app/services/messaging.service";
import { SocketService } from "../../services/socket.service";
import { MixpanelService } from "../../services/mixpanel.service";

@Component({
  selector: "app-admin-layout",
  templateUrl: "./admin-layout.component.html",
  styleUrls: ["./admin-layout.component.scss"],
})
export class AdminLayoutComponent implements OnInit {
  isMobileResolution: boolean;
  token: any;
  myDetails: any;
  constructor(
    private sessionService: SessionService,
    private apiService: ApiService,
    private ms: MessagingService,
    private socket: SocketService,
    private mixpanelService: MixpanelService
  ) {
    this.ms.token.subscribe((res) => {
      this.token = res;
    });
  }

  ngOnInit() {
    this.fetchData();
  }

  fetchData() {
    let bodyParams = {
      deviceType: "WEB",
      deviceToken: this.token || this.sessionService.get("deviceToken"),
    };
    this.apiService
      .postData(apiConstants.PROFILE.DETAIL.URL, bodyParams, true)
      .subscribe((res) => {
        this.myDetails = res.data;
        this.sessionService.changeVerification(res.data.isVerified);
        this.sessionService.set("token", res.data.accessToken);
        this.sessionService.set("userData", res.data);
      });
  }

  startVideo(){
    var myVideo: any = document.getElementById("my_video_1");
    myVideo.play();
  }

  play() {
    let body = {};
    body["tutorialViewed"] = true;
    this.apiService
      .postData(apiConstants.PROFILE.EDIT.URL, body, true)
      .subscribe(
        (res) => {
          this.mixpanelService.track("Tutorial Checked", {});
          // ------ ADD ANY SUCCESS MESSAGE OVER HERE
        },
        (err) => {}
      );
  }

  closeVideo() {
    this.myDetails.tutorialViewed = true;
    let x = this.sessionService.get("userData");
    x["tutorialViewed"] = true;
    this.sessionService.set("userData", x);
  }
}
