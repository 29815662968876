import { Injectable } from "@angular/core";
import {
  Router,
  CanActivate,
  CanDeactivate,
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
} from "@angular/router";
import { Observable } from "rxjs";
import { SessionService } from "./session.service";

@Injectable()
export class AuthgaurdService implements CanActivate {
  constructor(public router: Router, private sessionService: SessionService) {}

  canActivate(): boolean {
    if (!this.isAuthenticated()) {
      this.router.navigate(["/auth/login"]);
      return false;
    } else return true;
  }

  isAuthenticated(): boolean {
    const token = localStorage.getItem("token");
    return !!token;
  }
}
