import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from "@angular/core";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { AdminLayoutComponent } from "./layouts/admin-layout/admin-layout.component";
import { AuthLayoutComponent } from "./layouts/auth-layout/auth-layout.component";

import { NavbarComponent } from "./common/navbar/navbar.component";

import { ToastrModule } from "ngx-toastr";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";

import { ButtonLoaderModule } from "./common/button-loader/button-loader.module";
import { MainLoaderModule } from "./common/main-loader/main-loader.module";
import { GlobalConfirmModule } from "./common/global-confirm-popup/global-confirm.module";

import { AuthgaurdService } from "./services/authgaurd.service";
import { ApiService } from "./services/api.service";
import { InterceptorService } from "./services/interceptor.service";
import { SessionService } from "./services/session.service";
import { MixpanelService } from "./services/mixpanel.service";

import { LoginguardService } from "./services/loginguard.service";
import { Ng2TelInputModule } from "ng2-tel-input";
import { ModalModule } from "ngx-bootstrap/modal";
import { NotificationsComponent } from "./common/notifications/notifications.component";
import { AngularFireMessagingModule } from "@angular/fire/messaging";
import { AngularFireModule } from "@angular/fire";
import { environment } from "src/environments/environment";
import { MessagingService } from "./services/messaging.service";
import { AsyncPipe } from "@angular/common";

@NgModule({
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    AuthLayoutComponent,
    NavbarComponent,
    NotificationsComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: "toast-top-right",
      preventDuplicates: true,
      progressAnimation: "increasing",
      resetTimeoutOnDuplicate: true,
      progressBar: true,
    }),
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    Ng2TelInputModule,
    ButtonLoaderModule,
    MainLoaderModule,
    ModalModule.forRoot(),
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebase),
    GlobalConfirmModule,
  ],
  providers: [
    AuthgaurdService,
    ApiService,
    InterceptorService,
    SessionService,
    LoginguardService,
    MixpanelService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true,
    },
    MessagingService,
    AsyncPipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
