import { Component } from "@angular/core";
import { SessionService } from "./services/session.service";
import { MessagingService } from "./services/messaging.service";
import { MixpanelService } from "./services/mixpanel.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent {
  title = "TRANSPORTER-PANEL";
  message;
  checkOfflineStatus = false;
  constructor(
    private sessionService: SessionService,
    private messagingService: MessagingService,
    private mixpanelService: MixpanelService
  ) {
    this.sessionService.createOnline$().subscribe((isOnline) => {
      if (isOnline && this.checkOfflineStatus) {
        this.sessionService.getInternetStatus(true);
        this.sessionService.success("Internet Connected, Refresh Page!");
      } else if (!isOnline) {
        this.sessionService.getInternetStatus(false);
        this.checkOfflineStatus = true;
        this.sessionService.error("Internet Connection Lost!");
      }
    });
  }
  token;
  ngOnInit() {
    this.mixpanelService.init();
    this.messagingService.requestPermission();
    // this.token = this.messagingService.token
    // this.messagingService.receiveMessage()
    // this.message = this.messagingService.currentMessage
  }
}
