import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainLoaderComponent } from './main-loader.component';



@NgModule({
  declarations: [MainLoaderComponent],
  imports: [

  ],
  exports:[MainLoaderComponent]
})
export class MainLoaderModule { }
