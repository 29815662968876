import { NgModule } from "@angular/core";

import { ButtonLoaderComponent } from "./button-loader.component";

@NgModule({
  imports: [],
  declarations: [ButtonLoaderComponent],
  exports: [ButtonLoaderComponent],
})
export class ButtonLoaderModule {}
