import { NgModule } from "@angular/core";

import { GlobalConfirmComponent } from "./confirm.component";

@NgModule({
  imports: [],
  declarations: [GlobalConfirmComponent],
  exports: [GlobalConfirmComponent],
})
export class GlobalConfirmModule {}
