import {
  Component,
  OnInit,
  OnChanges,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  TemplateRef,
  ElementRef,
} from "@angular/core";
import { Route, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { ApiService } from "../../services/api.service";
import { SessionService } from "../../services/session.service";
import { apiConstants } from "../../shared/api.constants";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";

@Component({
  selector: "app-global-confirm",
  templateUrl: "confirm.component.html",
  styleUrls: ["./confirm.component.scss"],
})
export class GlobalConfirmComponent implements OnInit, OnChanges {
  @Input() openPopUp: boolean;
  @Input() title: any;
  @Input() message: any;
  @Output() closeModal = new EventEmitter();
  @Output() confirmed = new EventEmitter();
  @ViewChild("confirm", { static: false }) elementView: TemplateRef<any>;
  isLoading = false;
  modalRef: BsModalRef;
  constructor(
    private sessionService: SessionService,
    private apiService: ApiService,
    private taostr: ToastrService,
    private router: Router,
    private modalService: BsModalService
  ) {}

  ngOnInit() {}

  ngOnChanges(val) {
    if (this.openPopUp) {
      this.isLoading = false;
      this.openModalPublish(this.elementView);
    }
  }

  /*****popup open template Ref Through******/
  openModalPublish(template: TemplateRef<any>) {
    //this.modalRef = this.modalService.show(template, {class: 'modal-md modal-dialog-centered',});
    if (this.modalRef) this.modalRef.hide();
    this.modalRef = this.modalService.show(template, {
      class: "modal-md modal-dialog-centered",
      backdrop: "static",
      keyboard: false,
    });
  }
  decline(): void {
    this.closeModal.emit(false);
    this.modalRef.hide();
  }
  confirmMilestone() {
    if (this.isLoading) return false;
    this.isLoading = true;
    this.confirmed.emit(true);
    this.modalRef.hide();
  }
}
