import {
  Component,
  OnInit,
  ElementRef,
  Renderer2,
  Input,
  OnChanges,
} from "@angular/core";
import { SessionService } from "src/app/services/session.service";
import { ApiService } from "src/app/services/api.service";
import { EMAIL_REGEX, apiConstants } from "../../shared/api.constants";
import { Router } from "@angular/router";
import { MessagingService } from "src/app/services/messaging.service";
import * as moment from "moment";

@Component({
  selector: "app-notifications",
  templateUrl: "./notifications.component.html",
  styleUrls: ["./notifications.component.css"],
})
export class NotificationsComponent implements OnInit, OnChanges {
  @Input() gg: string;
  data: any[] = [];
  workingCountry = [];
  unread: any;
  currentDate: Date = new Date();
  pageNumber = 0;
  notificationsPerPage = 2;
  isLoading = false;
  msg;
  params;
  totalcount;
  userData: any;
  yesterday = new Date(
    this.currentDate.setDate(this.currentDate.getDate() - 1)
  );
  daybefore = this.currentDate.setDate(this.currentDate.getDate() - 2);

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private sessionService: SessionService,
    private apiService: ApiService,
    private router: Router,
    private ms: MessagingService
  ) {}

  back() {
    this.renderer.removeClass(
      this.elementRef.nativeElement.querySelector("#sidebar_false"),
      "__active"
    );
    this.isLoading = false;
  }
  loadMore(pageNumber) {
    this.pageNumber++;
    this.fetchNotifications();
  }
  getNormalTime(date) {
    let lastdot = date.lastIndexOf(".");
    let masterDate = date.substring(0, lastdot);
    let currentDate = new Date(masterDate);
    return currentDate;
  }
  compareDates(date) {
    let lastdot = date.lastIndexOf(".");
    let masterDate = date.substring(0, lastdot);
    let currentDate = new Date(masterDate);
    let yesterdayDate = new Date(this.yesterday);
    let today = new Date();
    // console.log(date)
    if (
      currentDate.getDate() == today.getDate() &&
      currentDate.getMonth() == today.getMonth() &&
      currentDate.getFullYear() == today.getFullYear()
    ) {
      // console.log("todaysis corrent",date,currentDate,currentDate.getDate() , today.getDate(),currentDate.getDate() == today.getDate());
      return 1;
    } else if (
      currentDate.getDate() == yesterdayDate.getDate() &&
      currentDate.getMonth() == yesterdayDate.getMonth() &&
      currentDate.getFullYear() == yesterdayDate.getFullYear()
    ) {
      return 2;
    } else return 3;
  }
  fetchNotifications() {
    this.isLoading = true;
    this.params = {
      limit: this.notificationsPerPage,
      skip: this.notificationsPerPage * this.pageNumber,
    };
    this.apiService
      .postData(
        apiConstants.NOTIFICATIONS.GET_NOTIFICATION.URL,
        this.params,
        true
      )
      .subscribe(
        (res) => {
          this.unread = res.data.unread;
          this.data = [...this.data, ...res.data.list];
          if (this.pageNumber < 2) this.totalcount = res.data.count;
          this.isLoading = false;

          this.data.forEach((k, i) => {
            k["assignmentListing"].forEach((j) => {
              if (j.type == 17) {
                j.message +=
                  " It will expires by  " +
                  this.formatDataTime(j.jobExpiryDate) +
                  " time. Place bid now.";
              }
            });
          });
        },
        (err) => {
          this.isLoading = false;
        }
      );
  }
  getTimeDiff(datetime) {
    let x: any = new Date();
    let y: any = new Date(datetime);
    let ms = x - y;
    return Math.floor(ms / 60000);
  }
  markAsRead(id, type, i, j, model, jobId) {
    this.apiService
      .postData(apiConstants.NOTIFICATIONS.MARK_AS_READ.URL, { _id: id }, true)
      .subscribe(
        (res) => {
          this.data[i].assignmentListing[j].isRead = true;
          this.updateNotificationBadge();
        },
        (err) => {}
      );
    this.back();
    setTimeout(() => {
      if (type == 16) {
        this.router.navigate(["/orders"], { queryParams: { type: "past" } });
      }
      if (type == 8) {
        this.router.navigate(["/orders"], {
          queryParams: { type: "new", search: jobId },
        });
      }
      if (type == 13) {
        this.router.navigate(["/orders"], { queryParams: { type: "ongoing" } });
      }
      if (type == 12 || type == 14 || type == 15) {
        this.router.navigate(["/orders"]);
      }
      if (type == 6 || type == 11 || type == 7 || type == 9) {
        this.router.navigate(["/drivers"]);
      }
      if (type == 2) {
        if (model == "Bid") {
          this.router.navigate(["all-bids/bid"], {
            queryParams: { jobId: jobId },
          });
        }
        if (model == "Fixed") {
          this.router.navigate(["all-bids/fixed-rate"]);
        }
      }
      if (type == 10) {
        this.router.navigate(["all-bids/fixed-rate"]);
      }
      if (type == 17) {
        this.router.navigate(["my-bids"], {
          queryParams: { jobId: jobId },
        });
      }
      if (type == 3 || type == 4 || type == 17) {
        this.router.navigate(["my-bids"], {
          queryParams: { jobId: jobId },
        });
      }
    }, 600);
  }
  markAllRead() {
    this.unread = 0;
    this.userData = this.sessionService.get("userData");
    this.workingCountry = this.userData.workingCountry;
    let obj = {
      isReadAll: true,
      workingCountry: [this.workingCountry],
    };
    this.apiService
      .postData(apiConstants.NOTIFICATIONS.READ_ALL_NOTIFICATION.URL, obj, true)
      .subscribe(
        (res) => {
          this.data.forEach((k, i) => {
            k["assignmentListing"].forEach((j) => {
              j.isRead = true;
            });
          });

          this.updateNotificationBadge();
        },
        (err) => {}
      );
  }
  updateNotificationBadge() {
    let bodyParams = {
      deviceType: "WEB",
    };
    this.apiService
      .postData(apiConstants.PROFILE.DETAIL.URL, bodyParams, true)
      .subscribe((res) => {
        this.sessionService.changeProfile(res.data);
        this.sessionService.set("token", res.data.accessToken);
        this.sessionService.set("userData", res.data);
      });
  }
  ngOnInit() {
    this.ms.receiveMessage();
    this.msg = this.ms.currentMessage;
    // this.ms.receiveMessage();
    this.ms.currentMessage.subscribe(
      (msg) => {
        this.pageNumber = 0;
        this.data = [];
        this.fetchNotifications();
      },
      (err) => {}
    );
  }

  formatDataTime(date) {
    return moment(date).format("MMM Do YYYY HH:mm");
  }
  //partially removed will be handled
  ngOnChanges() {
    if (this.gg) {
      this.pageNumber = 0;
      this.data = [];
      this.fetchNotifications();
    }
  }
}
