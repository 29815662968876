export const apiConstants = {
  AUTH: {
    LOGIN: {
      URL: "/transporter/login",
    },
    LOGINOTP: {
      URL: "/transporter/loginViaOtp",
    },
    LOGOUT: {
      URL: "/transporter/logout",
    },
    FORGOT: {
      URL: "/transporter/forgotPassword",
    },
    REGISTER: {
      URL: "/transporter/signUp",
    },
    CHANGE_PASSWORD: {
      URL: "/transporter/changePasswordByToken",
    },
    VERIFY_OTP: {
      URL: "/transporter/verifyOtp",
    },
  },
  IMAGE: {
    UPLOAD: {
      URL: "/dmsAdmin/uploadImage",
    },
  },
  DRIVERS: {
    LIST: {
      URL: "/transporter/driverList",
    },
    DETAILS: {
      URL: "/transporter/driverDetails",
    },
    JOB_DETAILS: {
      URL: "/transporter/driverAssignments",
    },
    EARNINGS: {
      URL: "/transporter/driverEarnings",
    },
    PREFERRED_LOCATION: {
      URL: "/driver/getPreferredLocation",
    },
    ADD: {
      URL: "/transporter/addDriver",
    },
    EDIT: {
      URL: "/transporter/editDriver",
    },
  },
  PROFILE: {
    DETAIL: {
      URL: "/transporter/accessTokenLogin",
    },
    EDIT: {
      URL: "/transporter/editTransporter",
    },
    CHANGE_PASSWORD_PROFILE: {
      URL: "/transporter/changePassword",
    },
  },
  BIDDING: {
    MY_BID: {
      URL: "/transporter/myTender",
    },
    ALLBID: {
      URL: "/transporter/jobList",
    },
    JOB_BY_ID: {
      URL: "/transporter/jobById",
    },
    APPLY: {
      URL: "/transporter/applyJob",
    },
  },
  NOTIFICATIONS: {
    GET_NOTIFICATION: {
      URL: "/transporter/notificationList",
    },
    MARK_AS_READ: {
      URL: "/transporter/notificationMarkAsRead",
    },
    READ_ALL_NOTIFICATION: {
      URL: "/transporter/readNotification",
    },
  },
  ORDERS: {
    LIST: {
      URL: "/transporter/newjobList",
    },
    NEW_ASSIGNMENT_COUNT: {
      URL: "/transporter/newAssignmentCount",
    },
    DETAILS: {
      URL: "/transporter/newJobDetails",
    },
    LIST_FOR_ASSIGN: {
      URL: "/transporter/transporterOwnerAndDriver",
    },
    MILESTONE: {
      URL: "/transporter/milestoneList",
    },
    ASSIGN_DRIVER: {
      URL: "/transporter/newAssignDriver",
    },
    REMOVE_DRIVER: {
      URL: "/transporter/removeDriver",
    },
    UPDATE_MILESTONE: {
      URL: "/transporter/updateAssignMilestone",
    },
    MILESTONE_COMMENTS: {
      URL: "/transporter/editComments",
    },
    DRIVER_PATH: {
      URL: "/transporter/assignmentPathDetails",
    },
    ADD_PROOF: {
      URL: "/transporter/addProofOfDeliveryForAssignment",
    },
    SHIPMENT_TYPES: {
      URL: "/transporter/getShipmentType",
    },
  },
  DOCUMENTS: {
    SHIPMENT: {
      URL: "/transporter/getShipmentDocument",
    },
    ADVANCE: {
      URL: "/transporter/getAdvanceList",
    },
    ADDITIONAL_CHARGES: {
      URL: "/transporter/getAdditionalCharges",
    },
    PROOF_OF_DELIVERY: {
      URL: "/transporter/viewProofOfDeliveryForAssignment",
    },
    DRIVER_ONBOARDING: {
      URL: "/transporter/driverDetails",
    },
    ASSET_HANDOVER: {
      URL: "/transporter/assetHandoverForm",
    },
    WAYBILL: {
      URL: "/transporter/waybill",
    },
    UPLOAD_SHIPMENT_DOCUMENT: {
      URL: "/transporter/uploadShipmentDocument",
    },
  },
  ACTIVITY_LOGS: {
    ORDERS: {
      URL: "/transporter/orderActivityLogs",
    },
  },
};

export const EMAIL_REGEX =
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/;

export const PHONE_REGEX =
  /^[(]{0,1}[0-9]{3}[)]{0,1}[-\s\.]{0,1}[0-9]{3}[-\s\.]{0,1}[0-9]{4}$/;

export const PASSWORD_REGEX =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W\_])[A-Za-z\d\W\_]{6,}$/;

export const USER_NAME = /^[a-z0-9_.]{6,20}$/;

export const MOBILE_NUMBER = /^(?=.*\d)(?=.*[1-9]).{5,15}$/;

export const NATIONALITIES = [
  {
    text: "Indian",
    value: "Indian",
  },
  {
    text: "Pakistani",
    value: "pakistani",
  },
  {
    text: "Sudani",
    value: "sudani",
  },
  {
    text: "Yemeni",
    value: "yemeni",
  },
  {
    text: "Egyptian",
    value: "egyptian",
  },
  {
    text: "Nepali",
    value: "nepali",
  },
  {
    text: "Sri Lankan",
    value: "sri lankan",
  },
  {
    text: "Bangladeshi",
    value: "bangladeshi",
  },
  {
    text: "Syrian",
    value: "syrian",
  },
  {
    text: "Saudi Arabian",
    value: "saudi Arabian",
  },
  {
    text: "Omani",
    value: "omani",
  },
  {
    text: "Bahraini",
    value: "bahraini",
  },
  {
    text: "Kuwaiti",
    value: "kuwaiti",
  },
  {
    text: "Others",
    value: "others",
  },
];
