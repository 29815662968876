import { Injectable } from "@angular/core";
import { ToastrModule, ToastrService } from "ngx-toastr";
import { BehaviorSubject, fromEvent, merge, Observable, Observer } from "rxjs";
import { ApiService } from "./api.service";
import { resolve } from "q";
import { FormControl } from "@angular/forms";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class SessionService {
  constructor(private toastr: ToastrService, private api: ApiService) {}

  private updateVerification = new BehaviorSubject<any>(null);
  private updateDriverDetail = new BehaviorSubject<any>(null);
  private updateProfile = new BehaviorSubject<any>(null);
  private updatePassword = new BehaviorSubject<any>(null);

  // for internetConnection
  private checkOnline = new BehaviorSubject<any>(null);
  public onlineStatus = this.checkOnline.asObservable();
  getInternetStatus(status) {
    this.checkOnline.next(status);
  }
  createOnline$() {
    return merge<boolean>(
      fromEvent(window, "offline").pipe(map(() => false)),
      fromEvent(window, "online").pipe(map(() => true)),
      new Observable((sub: Observer<boolean>) => {
        sub.next(navigator.onLine);
        sub.complete();
      })
    );
  }

  public newOrderData = new BehaviorSubject(Array);
  currentData = this.newOrderData.asObservable();

  public newEditDriver = new BehaviorSubject(Array);
  driverData = this.newEditDriver.asObservable();
  public newLowerBid = new BehaviorSubject(Array);
  LowerBid = this.newLowerBid.asObservable();

  public newLocationData = new BehaviorSubject(Array);
  currentLocation = this.newLocationData.asObservable();

  public newBidAssignedData = new BehaviorSubject(Array);
  bidAssignedData = this.newBidAssignedData.asObservable();

  onNewOrderData(data) {
    this.newOrderData.next(data);
  }

  onNewLocationData(data) {
    this.newLocationData.next(data);
  }

  onNewBidAssignedData(data) {
    this.newBidAssignedData.next(data);
  }
  onEditDriverData(data) {
    this.newEditDriver.next(data);
  }
  onEditLower(data) {
    this.newLowerBid.next(data);
  }

  changeVerification(value) {
    this.updateVerification.next(value);
  }

  getChangedVerification(): Observable<any> {
    return this.updateVerification.asObservable();
  }

  changeDriverDetail(value) {
    this.updateDriverDetail.next(value);
  }

  getDriverDetail(): Observable<any> {
    return this.updateDriverDetail.asObservable();
  }

  changeProfile(value) {
    this.updateProfile.next(value);
  }

  getProfileDetail(): Observable<any> {
    return this.updateProfile.asObservable();
  }

  // ---------------- change password popup open close methods
  callChangePasword(value) {
    this.updatePassword.next(value);
  }

  handleChangePasswordPop(): Observable<any> {
    return this.updatePassword.asObservable();
  }

  get(key) {
    if (localStorage.getItem(key)) {
      return JSON.parse(localStorage.getItem(key));
    } else return null;
  }

  set(key, value) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  remove(key) {
    localStorage.removeItem(key);
  }

  setTouched(form) {
    Object.keys(form.controls).forEach((key) => {
      form.controls[key].markAsTouched({ onlySelf: true });
    });
  }

  success(m) {
    this.toastr.success(m);
  }

  error(m) {
    this.toastr.error(m);
  }

  info(m) {
    this.toastr.info(m);
  }

  checkPicture(file) {
    if (file) {
      if (file.size < 5000000) {
        if (
          file.type === "image/jpeg" ||
          file.type === "image/jpg" ||
          file.type === "image/png"
        ) {
          return true;
        } else {
          this.toastr.error("Please add jpg/png files only");
        }
      } else {
        this.toastr.error("Please add file with size less than 5 MB");
      }
    }
    return false;
  }

  checkImage(file) {
    if (file) {
      if (file.size < 5000000) {
        if (
          file.type === "image/jpeg" ||
          file.type === "image/jpg" ||
          file.type === "image/png" ||
          file.type === "application/pdf"
        ) {
          return true;
        } else {
          this.toastr.error("Please add jpg/png/pdf files only");
        }
      } else {
        this.toastr.error("Please add file with size less than 5 MB");
      }
    }
    return false;
  }

  checkExcel(sFileName) {
    let _validFileExtensions;
    let blnValid;
    if (sFileName) {
      _validFileExtensions = [".xlsx", ".xls", ".xl", ".csv"];
      blnValid = false;
      for (let j = 0; j < _validFileExtensions.length; j++) {
        const sCurExtension = _validFileExtensions[j];
        if (
          sFileName
            .substr(
              sFileName.length - sCurExtension.length,
              sCurExtension.length
            )
            .toLowerCase() === sCurExtension.toLowerCase()
        ) {
          blnValid = true;
          return true;
        }
      }
    }
    return false;
  }

  checkfileType(sFileName) {
    let _validFileExtensions;
    let blnValid;
    if (sFileName) {
      _validFileExtensions = [".jpg", ".jpeg", ".bmp", ".gif", ".png"];
      blnValid = false;
      for (let j = 0; j < _validFileExtensions.length; j++) {
        const sCurExtension = _validFileExtensions[j];
        if (
          sFileName
            .substr(
              sFileName.length - sCurExtension.length,
              sCurExtension.length
            )
            .toLowerCase() === sCurExtension.toLowerCase()
        ) {
          blnValid = true;

          return true;
        }
      }
    }
    return false;
  }

  checkTime() {
    const today = new Date();
    const curHr = today.getHours();

    if (curHr < 12) {
      return "Good Morning";
    } else if (curHr < 16) {
      return "Good Afternoon";
    } else {
      return "Good Evening";
    }
  }

  convertTime12to24(time12h) {
    const [time, modifier] = time12h.split(" ");

    let [hours, minutes] = time.split(":");

    if (hours === "12") {
      hours = "00";
    }

    if (modifier === "PM") {
      hours = parseInt(hours, 10) + 12;
    }

    return `${hours}:${minutes}`;
  }

  public noWhitespaceValidator(control: FormControl) {
    if (control.value) {
      const isWhitespace = (control.value || "").trim().length === 0;
      const isValid = !isWhitespace;
      return isValid ? null : { whitespace: true };
    }
  }
}
